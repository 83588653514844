<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.solicitudes.pivotTable'
                          )
                        "
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-solicitudes-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.solicitudExport'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('hidrocarburos.solicitudes.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="solicitudes"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        #
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nominación
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="nominacion"
                          label="nombre"
                          :options="listasForms.nominaciones"
                          placeholder="Nominacion"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Sitio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="sitio"
                          label="nombre"
                          :options="listasForms.sitios"
                          placeholder="Sitio"
                          @input="getSelectSitio()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.id"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th align="center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitud in solicitudes.data"
                      :key="solicitud.id"
                    >
                      <td>{{ solicitud.id }}</td>
                      <td v-if="solicitud.nominacion != null">
                        {{ solicitud.nominacion.nombre }}
                      </td>
                      <td v-else>Sin nominación</td>
                      <td>{{ solicitud.sitio.nombre }}</td>
                      <td>{{ solicitud.fecha }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud.estado == 1
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                        >
                          {{ solicitud.nEstado }}
                        </span>
                      </td>
                      <td align="center">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.solicitudes.show'
                              )
                            "
                            @click="edit(solicitud)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-on:click="destroy(solicitud.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.solicitudes.delete'
                              ) && solicitud.estado != 2
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  <p>
                    Mostrando del <b>{{ solicitudes.from }}</b> al
                    <b>{{ solicitudes.to }}</b> de un total:
                    <b>{{ solicitudes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <SolicitudExport ref="SolicitudExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import SolicitudExport from "./SolicitudExport";
import vSelect from "vue-select";
export default {
  name: "Solicitudes",
  components: {
    pagination,
    Loading,
    vSelect,
    SolicitudExport,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        estado: null,
      },
      sitio: {},
      nominacion: null,
      solicitudes: {},
      listasForms: {
        estados: [],
        sitios: [],
        nominaciones: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      if (this.nominacion != null) {
        this.filtros.nominacion_id = this.nominacion.id;
      } else {
        this.filtros.nominacion_id = null;
      }
      axios
        .get("/api/hidrocarburos/solicitudes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      this.cargando = true;
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
        this.cargando = false;
      });
    },

    getNominaciones() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/nominacion/list").then((response) => {
        this.listasForms.nominaciones = response.data;
        this.cargando = false;
      });
    },

    getSitios() {
      this.cargando = true;
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
        this.cargando = false;
      });
    },

    getSelectSitio() {
      this.filtros.sitio = this.sitio.id;
      this.getIndex();
    },

    destroy(idSolicitud) {
      this.$swal({
        title: "Esta seguro de eliminar esta Solicitud?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/solicitudes/" + idSolicitud)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino la Solicitud exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/SolicitudesFormCreate",
        params: { accion: "Crear", id: null },
      });
    },

    edit(solicitud) {
      return this.$router.push({
        name: "/Hidrocarburos/SolicitudesFormEdit",
        params: { accion: "Editar", data_edit: solicitud, id: solicitud.id },
      });
    },
    pivotTable() {
      return this.$router.push({
        name: "/Hidrocarburos/SolicitudesPivotTable",
      });
    },
  },
  mounted() {
    this.getEstados();
    this.getNominaciones();
    this.getSitios();
    this.getIndex();
  },
};
</script>
